html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

@media only screen and (min-width: 768px) {
  body {
    background-size: 100% auto;
    background-image: url('heeros.png');
    background-repeat: no-repeat;
    background-position-y: 64px;
  }
}
